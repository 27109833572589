import { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import styled, { css } from 'styled-components'
import Image from '../Image'
import Chevron from '../icons/Chevron'

function Gallery( { images } ) {
  const [currentSlide, setCurrentSlide] = useState( 0 )

  const gotoSlide = slideIndex => {
    if ( slideIndex >= 0 && slideIndex < images.length ) {
      setCurrentSlide( slideIndex )
    }
  }

  const previous = () => {
    gotoSlide( currentSlide - 1 )
  }
  
  const next = () => {
    gotoSlide( currentSlide + 1 )
  }

  const handlers = useSwipeable( {
    onSwipedLeft: next,
    onSwipedRight: previous,
  } )

  return (
    <StyledGallery 
      $position={ currentSlide }
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...handlers }
    >
      <div>
        { images.map( ( image, i ) => (
          <StyledGalleryItem 
            key={ image.id }
            $isActive={ i === currentSlide }
            onClick={ () => gotoSlide( i ) }
          >
            <StyledGalleryImage>
              <Image
                src={ image.image[ 0 ].url }
                alt={ image.image[ 0 ].url }
                layout="fill"
                objectFit="cover"
                sizes="min( 40rem, 87vw )"
                unoptimized={ image.image[ 0 ].animatedGif }
              />
            </StyledGalleryImage>
            { image.caption && (
            <StyledGalleryCaption>{ image.caption }</StyledGalleryCaption>
            ) }
          </StyledGalleryItem>
        ) ) }
      </div>
      <StyledGalleryControls>
        <button
          type="button"
          disabled={ currentSlide === 0 }
          onClick={ previous }
        >
          Previous
          <Chevron />
        </button>
        <button
          type="button"
          disabled={ currentSlide === images.length - 1 }
          onClick={ next }
        >
          next
          <Chevron />
        </button>
      </StyledGalleryControls>
    </StyledGallery>
  )
}

interface WithPosition {
  $position: number,
}

const StyledGallery = styled.div<WithPosition>`
  > div:first-child {
    position: relative;
    display: flex;
    transform: translateX( ${ p => p.$position * -105 }% );
    transition: transform .3s ease-out;
  }
`

const StyledGalleryControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button {
    position: relative;
    margin: 0 0.5rem;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: 1px solid ${ p => p.theme.colors.black };
    background: transparent;
    text-indent: -999rem;
    cursor: pointer;
    transition:
      border-color .3s ease-out,
      opacity .3s ease-out;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate( -50%, -50% );
      display: block;
      width: 30%;

      path {
        stroke: ${ p => p.theme.colors.black };
        transition: stroke .3s ease-out;
      }
    }

    &:not( :disabled ):hover {
      border-color: ${ p => p.theme.colors.red };

      svg path {
        stroke: ${ p => p.theme.colors.red };
      }
    }

    &:disabled {
      opacity: 0.3;
    }

    &:first-child {
      svg {
        transform: 
          translate( -50%, -50% )
          rotate( 180deg );
      }
    }
  }
`

interface WithIsActive {
  $isActive: boolean,
}

const StyledGalleryItem = styled.figure<WithIsActive>`
  position: relative;
  flex: 0 0 100%;
  margin: 0 5% 0 0;
  padding: 0;
  opacity: 0.5;
  will-change: transform, opacity;
  cursor: ${ p => p.$isActive ? 'default' : 'pointer' };
  transition:
    opacity .6s ease-out;

  ${ p => p.$isActive && css`
    z-index: 1;
    opacity: 1;

    ${ StyledGalleryCaption } {
      opacity: 1;
      transition: opacity .3s ease-out .3s;
    }
  ` }
`

const StyledGalleryImage = styled.div`
  position: relative;
  padding-bottom: 56%;
`

const StyledGalleryCaption = styled.figcaption`
  position: relative;
  margin-top: 0.5rem;
  ${ p => p.theme.typo.generalSans };
  font-size: ${ p => p.theme.typo.sizes.dropped };
  opacity: 0;
  transition: opacity .3s ease-out;
`

export default Gallery
