import { useEffect, useRef, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { nanoid } from 'nanoid/non-secure'
import DocumentCloudEmbed from './DocumentCloudEmbed'

function Embed( { 
  oEmbed, 
  unsupportedUrl, 
  containerWidth,
  iframeWidth,
  iframeHeight,
} ) {
  const embedContainerRef = useRef( null )
  const iframeId = useMemo( () => nanoid(), [] )

  useEffect( () => {
    if ( embedContainerRef.current ) {
      const iframe = embedContainerRef.current?.getElementsByTagName( 'iframe' )?.[ 0 ]

      if ( iframe ) {
        const srcUrl = new URL( iframe.src )

        const onMessage = e => {
          const data = e.message || e.data

          if ( data.id === iframeId && data.documentHeight ) {
            iframe.style.height = `${ parseInt( data.documentHeight, 10 ) }px`
          }
        }
        
        iframe.onload = () => {
          iframe.contentWindow.postMessage( { question: 'howHigh?', id: iframeId }, srcUrl.origin )
        }

        window.addEventListener( 'message', onMessage )

        return () => {
          iframe.onload = null
          window.removeEventListener( 'message', onMessage )
        }
      }
    }
  }, [iframeId] )

  if ( !oEmbed?.code && !unsupportedUrl ) {
    return null
  }

  if ( !unsupportedUrl && oEmbed?.code ) {
    switch ( oEmbed.type ) {
      case 'video':
        return (
          <Video 
            html={ oEmbed.code } 
            width={ oEmbed.width }
            height={ oEmbed.height }
            aspectRatio={ oEmbed.aspectRatio }
          />
        )

      default:
        return (
          <StyledEmbedCode
            ref={ embedContainerRef }
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={ { __html: oEmbed.code } } 
            $iframeHeight={ iframeHeight }
            $iframeWidth={ iframeWidth }
          />
        )
    }
  }

  const provider = getProvider( unsupportedUrl )

  switch ( provider ) {
    case 'documentcloud':
      return (
        <DocumentCloudEmbed 
          documentUrl={ unsupportedUrl } 
          maxWidth={ containerWidth }
        />
      )

    default:
      return null
  }
}

function Video( {
  html, 
  width, 
  height, 
  aspectRatio, 
} ) {
  const ratio = aspectRatio || ( height && width ) ? height / width : null

  return (
    <StyledVideo 
      $aspectRatio={ ratio } 
      dangerouslySetInnerHTML={ { __html: html } }
    />
  )
}

const StyledEmbedCode = styled.div<{
  $iframeWidth?: string,
  $iframeHeight?: string,
}>`
  iframe {
    ${ p => p.$iframeWidth && css`
      width: ${ p.$iframeWidth } !important;
    ` }
    
    ${ p => p.$iframeHeight && css`
      height: ${ p.$iframeHeight } !important;
    ` }
  }
`

const StyledVideo = styled.div<{ 
  $aspectRatio?: number,
}>`
  width: 100%;

  iframe,
  video,
  img {
    display: block;
  }

  ${ p => p.$aspectRatio && css`
    padding-bottom: $${ p.$aspectRatio }%;

    iframe,
    video {
      width: 100%;
      height: 100%;
    }
  ` }
`

function getProvider( url ) {
  const providers = [
    {
      name: 'soundcloud',
      pattern: /soundcloud\.com/i,
    },
    {
      name: 'documentcloud',
      pattern: /documentcloud\.org/i,
    },
    {
      name: 'vimeo',
      pattern: /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i,
    },
    {
      name: 'youtube',
      pattern: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/i,
    },
  ]

  for ( let i = 0, len = providers.length; i < len; i += 1 ) {
    if ( providers[ i ].pattern.test( url ) ) {
      return providers[ i ].name
    }
  }
}

export default Embed
