import { useEffect, useState } from 'react'

function getDocumentCloudOEmbed( url, maxWidth = null ) {
  const apiUrl = `https://api.www.documentcloud.org/api/oembed?url=${ url }${ maxWidth ? `&maxwidth=${ maxWidth }` : '' }`
  return fetch( apiUrl ).then( r => r.json() )
}

function DocumentCloudEmbed( { documentUrl, maxWidth } ) {
  const [oEmbed, setOEmbed] = useState( null )

  useEffect( () => {
    let mounted = true

    if ( documentUrl && maxWidth ) {
      getDocumentCloudOEmbed( documentUrl, maxWidth ).then( r => mounted && setOEmbed( r ) )
    }

    return () => {
      mounted = false
    }
  }, [documentUrl, maxWidth] )

  if ( !oEmbed?.html ) {
    return <p>Loading document…</p>
  }

  return (
    <div 
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={ { __html: oEmbed.html } } 
    />
  )
}

export default DocumentCloudEmbed
