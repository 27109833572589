import styled from 'styled-components'
import {
  useState, useCallback, createContext, useContext, useMemo,
} from 'react'

const PrintLinksContext = createContext( { urls: [], setUrls: urls => urls } )
PrintLinksContext.displayName = 'PrintLinksContext'

export const usePrintLinks = ( {
  selector = 'a[href]', 
  stripProtocols = /^mailto:|(https?|ftp):\/\//,
  supClassName = 'print-links-sup',
} = {} ) => {
  const { urls, setUrls } = useContext( PrintLinksContext )

  const printLinksRef = useCallback( node => {
    if ( node ) {
      const links = node.querySelectorAll( selector )
      const newUrls = []

      links.forEach( ( link, i ) => {
        // skip is this element has already been accounted for
        if (
          link.nextSibling?.nodeType !== Node.ELEMENT_NODE 
          || !link.nextSibling?.matches( `sup.${ supClassName }` ) 
        ) {
          const url = stripProtocols ? link.href.replace( stripProtocols, '' ) : link.href
          const urlIndex = urls.indexOf( url )
          const urlNumber = urlIndex > -1 ? urlIndex + 1 : i + 1
          const sup = document.createElement( 'sup' )
          sup.append( `${ urlNumber }` )
          sup.classList.add( supClassName )
          link.parentNode.insertBefore( sup, link.nextSibling )

          if ( urlIndex === -1 ) {
            newUrls.push( url )
          }
        }
      } )

      if ( newUrls.length ) {
        setUrls( existingUrls => [...existingUrls, ...newUrls] )
      }
    }
  }, [selector, setUrls, stripProtocols, supClassName, urls] )

  return { urls, printLinksRef }
}

export function PrintLinksProvider( { children } ) {
  const [urls, setUrls] = useState( [] )

  return (
    <PrintLinksContext.Provider 
      value={ useMemo( () => ( {
        urls,
        setUrls,
      } ), [urls] ) }
    >
      { children }
    </PrintLinksContext.Provider>
  )
}

function PrintLinks() {
  const { urls } = usePrintLinks()

  if ( !urls.length ) {
    return null
  }

  return (
    <StyledPrintLinks>
      <h2>Links</h2>
      <ol>
        { urls.map( ( url, i ) => (
          <li 
            // eslint-disable-next-line react/no-array-index-key
            key={ i }
          >
            { url }
          </li> 
        ) ) }
      </ol>
    </StyledPrintLinks>
  )  
}

const StyledPrintLinks = styled.aside`
  display: none;
  margin-bottom: 3rem;
  ${ p => p.theme.typo.generalSans }

  h2 {
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: ${ p => p.theme.typo.sizes.medium };
  }

  ol {
    margin: 0;  
  }

  li {
    font-size: ${ p => p.theme.typo.sizes.dropped };

    &::marker {
      color: ${ p => p.theme.colors.red };
    }
  }

  @media print {
    display: block;
  }
`

export default PrintLinks
