import styled, { css } from 'styled-components'

interface BackgroundBase {
  color?: string,
  image?: string,
  borderTop?: string,
  borderBottom?: string,
}

interface BackgroundMask extends BackgroundBase {
  color: string,
  mask: string,
  maskPosition: 'top' | 'bottom',
}

type Background = BackgroundBase | BackgroundMask

const Background = styled.div<Background>`
  ${ p => p.color && !( 'mask' in p && p.mask ) && css`
    background-color: ${ p.theme.getColor( p.color ) };
  ` }
  
  ${ p => p.color === 'black' && css`
    color: ${ p.theme.colors.white };
  ` }

  ${ p => p.image && css`
    background-image: url( ${ p.image } );
    background-size: cover;
  ` }

  ${ p => p.borderTop && css`
    border-top: 1px solid ${ p.theme.getColor( p.borderTop ) };
  ` }

  ${ p => p.borderBottom && css`
    border-bottom: 1px solid ${ p.theme.getColor( p.borderBottom ) };
  ` }

  ${ p => 'mask' in p && p.mask && css`
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background: ${ p.theme.getColor( p.color ) };
      mask-repeat: repeat-x;

      ${ p.maskPosition === 'top' && css`
        top: -15px;
        bottom: 0;
        mask-image:
          url( ${ p.mask } ),
          linear-gradient( transparent 10%, rgb( 0, 0, 0 ) 10%, rgb( 0, 0, 0 ) 90% );
        mask-position: top center;
      ` }

      ${ p.maskPosition === 'bottom' && css`
        top: 0;
        bottom: -8px;
        mask-image:
          url( ${ p.mask } ),
          linear-gradient( rgb( 0, 0, 0 ) 90%, rgb( 0, 0, 0 ) 10%, transparent 10% );
        mask-position: top center;
      ` }
    }
  ` }
`

Background.defaultProps = {
  mask: null,
  maskPosition: 'top',
}

export default Background
